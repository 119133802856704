import {
  get
} from "@/plugins/request";

const prefix = '/v1/products';

export function listProduct() {
  return get(`${prefix}`)
}
export function detailProduct(id) {
  return get(`${prefix}/${id}`)
}
export function listReleases(id) {
  return get(`${prefix}/${id}/releases`)
}
export function detailRelease(id, releaseId) {
  return get(`${prefix}/${id}/releases/${releaseId}`)
}

// 证书
export function listContributors(product) {
  return get(`/v1/${product}/contributors`)
}

export function getUserInfo(product, contributor) {
  return get(`/v1/${product}/contributors/${contributor}`)
}

