<template>
  <div class="product-detail">
    <el-skeleton :loading="loading" animated>
      <template>
        <div class="information">
          <h1 class="title">社区版下载</h1>
          <div class="release" v-if="versionList&&versionList.length>0">
            <!-- <el-row>
              <el-col :span="4" class="left">
                <p>
                  <span class="current-version">
                    <i class="iconfont icon-biaoqian"></i>
                    <b v-if="currentReleaseInfo">{{currentReleaseInfo.version}}</b></span>
                  <span v-if="islatestRelease" class="Latest-release">Latest</span>
                </p>
                <el-dropdown class="dropdown" @command="handleCommand">
                  <el-button>
                    其他版本<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="item.id===currentReleaseId"
                      v-for="(item,index) in versionList" :key="index" :command="item.id">
                      {{item.version}}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
              <el-col :span="20" class="right"> -->
            <!-- <div class="version-title">
                  <span v-if="currentReleaseInfo">{{currentReleaseInfo.version}}</span>
                  <el-dropdown v-if="currentReleaseInfo&&currentReleaseInfo.resourceList.length>0"
                    trigger="click" @command="downloadVersion" placement="bottom-start">
                    <el-tooltip class="item" effect="dark" content="下载此版本" placement="right-start">
                      <i class="icon el-icon-download"></i>
                    </el-tooltip>
                    <el-dropdown-menu slot="dropdown" style="margin-top:0">
                      <el-dropdown-item
                        v-for="(item, index) in currentReleaseInfo&&currentReleaseInfo.resourceList"
                        :key="index" :command="item.id" icon="el-icon-link">{{item.name}}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                </div> -->
            <el-table :data="currentReleaseInfo&&currentReleaseInfo.resourceList"
              style="width: 100%">
              <el-table-column prop="name" label="资源" min-width="30" width="360">
              </el-table-column>
              <el-table-column prop="id" label="下载">
                <template slot-scope="scope">
                  <el-button type="primary" circle plain size="mini" icon="el-icon-download"
                    @click="downloadVersion(scope.row.id)"></el-button>
                  <!-- <i class="icon el-icon-download"></i> -->
                </template>
              </el-table-column>
            </el-table>

            <!-- </el-col>
            </el-row> -->

          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import { listReleases, detailRelease, detailProduct } from "@/api/products";
import { mapGetters } from "vuex";
export default {
  name: "ProductDownload",
  data() {
    return {
      id: this.$route.params.id,
      currentReleaseId: this.$route.params.version,
      currentReleaseInfo: null,
      versionList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["proDetail"]),
    // islatestRelease() {
    //   return this.currentReleaseId == this.proDetail?.latestRelease;
    // },
  },
  watch: {
    // proDetail: {
    //   immediate: true,
    //   handler: function (val) {
    //     this.currentReleaseId = this.$route.params.version;
    //      this.currentReleaseInfo = val.latestReleaseInfo.filter(item=>item.id===this.$route.params.version);
    //   },
    // },
  },
  created() {
    this.getProDetail(this.$route.params.id);
    this.getListReleases();
    this.handleCommand(this.$route.params.version);
  },
  methods: {
    getProDetail(id) {
      this.loading = true;
      detailProduct(id).then((response) => {
        this.$store.commit("products/PRO_DETAIL", response);
        this.loading = false;
      });
    },
    getListReleases() {
      listReleases(this.id).then((response) => {
        this.versionList = response;
      });
    },
    handleCommand(command) {
      detailRelease(this.id, command).then((response) => {
        this.currentReleaseInfo = response;
      });
      this.currentReleaseId = command;
    },
    downloadVersion(resourceId) {
      const releaseId = this.currentReleaseInfo?.id;
      this.$store.dispatch("products/asynDownloadResources", {
        releaseId,
        resourceId,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.information {
  .release {
    // border-top: 1px solid #e1e4e8;
    margin: -15px 0 20px;

    .left {
      padding: 20px;
      text-align: right;
      .current-version {
        margin-right: 5px;
        font-size: 16px;
        .iconfont {
          margin-right: 5px;
          color: #22863a;
        }
      }
      .Latest-release {
        border: 1px solid #22863a;
        color: #22863a;
        border-radius: 10px;
        font-size: 12px;
        padding: 2px 5px;
      }
      // .dropdown {
      //   margin-top: 20px;
      // }
    }
    .right {
      padding: 20px;
      border-left: 1px solid #e1e4e8;
      .version-title {
        font-size: 24px;
        font-weight: bold;
        .icon {
          font-size: 24px;
          margin-left: 5px;
          color: #419756;
          cursor: pointer;
          &:hover {
            color: #25ab23;
          }
        }
      }
      // .version-info {
      //   margin-top: 10px;
      // }
    }
  }
}
</style>